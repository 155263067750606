<template>
  <div class="relative w-[300px] rounded-lg bg-[#596250]" >
    <div class="w-full h-[480px] relative shadow-md rounded-lg">
      <img 
        class="w-full h-full object-cover object-top" 
        src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/3e9eb945-b6f3-4546-cc57-9032f3762000/original"
      />  

      <div class="absolute top-0 right-0 bottom-0 left-0">
        <img 
          class="w-full h-full object-cover object-bottom" 
          src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/4e2747a9-fbb4-4702-0aa9-228720803f00/original"
        />  
      </div>
    </div>

    <div class="text-center" style="color: #FFF9D2;">
      
      <div  
        class="text-[9px] w-52 tracking-wide font-['cinzel'] uppercase absolute right-0" 
        style="top: 120px; color: #c5c1a7;"
        >
        <p>Save the Date</p>
        <p>For the wedding of</p>
      </div>

      <div
        class="text-center w-52 absolute right-0" 
        style="position: absolute; top: 165px; right: 0px;"
      >
        <div
          class="capitalize text-center tracking-wide text-[28px] py-2 space-y-2"
          :style="{
            fontFamily: props?.theme?.fonts?.welcome,
            color: '#FFF9D2',
            fontStyle: italic,
            lineHeight: 1,
          }"
        >
          <p>{{ props?.brideName }}</p>
          <p>& {{ props?.groomName }}</p>
        </div>
      </div>
    </div>


    <div
      class="text-center w-52"
      style="position: absolute; top: 250px; right: 0;"
    >
      <div
        class="text-[10px] capitalize text-center tracking-wide"
        style="font-family: cinzel; color: #c5c1a7;"
      >
        <p >{{dateNumber}}.{{mount}}.{{year}}</p>
      </div>
    </div>

    <div
      class="text-center w-52 flex flex-col items-center space-y-2  absolute right-0" 
      style="font-family: cinzel; position: absolute; top: 265px; right:0;  color: #c5c1a7;"
    >
      <img class="mt-2 w-24 h-auto object-contain" src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/57e6c737-694a-420e-0f39-caa70085c600/original" alt="">
      <p class="text-center text-[10px] tracking-wide uppercase text-[#c5c1a7] ">Ceremony</p>
    </div>

    <FooterButtons :theme="theme" />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import FooterButtons from './FooterButtons.vue'
const props = defineProps({
  brideName: { type: String, default: 'Bride'},
  groomName: { type: String, default: 'Groom'},
  eventDate: {type: String, default: '2014-12-25'},
  eventTimeStart: { type: String, default: '00:00'},
  theme: { type: Object, default: {}}
})

//-----
const dateNumber = computed( ()=> dayjs(props?.eventDate).format('DD') ||  dayjs().format('DD'))
const dayName = computed( ()=> dayjs(props?.eventDate).format('dddd') ||  dayjs().format('dddd'))
const mount = computed( ()=> dayjs(props?.eventDate).format('MMM') ||  dayjs().format('MMM'))
const year = computed( ()=> dayjs(props?.eventDate).format('YYYY') ||  dayjs().format('YYYY'))
const time = computed( ()=> props?.eventTimeStart ||  '00:00')
//-----
</script>

<style scoped>

</style>