<template>
  <div
    :class="{ 'opacity-40': eventPackage == 'free' }"
    class="max-w py-3 bg-white border border-gray-200 rounded-lg shadow "
  >
    <div class="flex items-center justify-between">
      <h2
        class="text-2xl font-semibold px-3 text-gray-600 pb-2 border-gray-300"
      >
        {{content?.title}}
      </h2>
      <button
        v-if="eventStore.isOwner"
        @click="toggleEditMode"
        class="py-1.5 px-3 mb-2 text-center bg-white border rounded-md text-gray-800 mr-2"
      >
        Edit
      </button>
    </div>
    <hr class="mb-3" />

    <div class="px-3 mb-5">
      <p class="font-semibold text-gray-800">{{content?.sort?.type}}</p>
      <p class="pl-2 text-gray-600">{{ produce?.sortType || ""}}</p>
      <p class="pl-2 text-sm italic text-gray-400" v-if="!produce?.sortType ">{{ content?.sort?.remark}}</p>
    </div>

    <div class="px-3 mb-5">
      <p class="font-semibold text-gray-800">{{content?.selectedType}}</p>
      <p class="pl-2 text-gray-600">{{ produce?.type || "-" }}</p>
      <p class="space-x-2 text-gray-600 italic text-sm mt-4 px-2" v-if="produce?.id === 'design-by-user'">
        <span><i class="fa-brands fa-youtube text-red-600"></i> {{content?.guidelineText}} : </span>
        <span class="text-blue-500 space-x-2" @click.stop="openLinkYoutube(content?.guidelineLink)" >{{content?.guidelineLink}} <i class="fa-solid fa-up-right-from-square"></i></span>
      </p>
    </div>


    <div class="px-3 my-5">
      <p class="font-semibold text-gray-800">{{content?.url}}</p>
      <p class="pl-2 text-gray-600">
        <span>{{ produce?.url || "-" }}</span>
      </p>
    </div>

    <div class="px-3 mt-5">
      <p class="text-sm text-gray-400 italic mt-2">{{content?.remark}}</p>
    </div>


    <!-- <div class="px-3 mb-2">
      <label class="text-gray-600  mt-2"
        >{{content?.produceBookDateLabel}} : {{ produce?.produce_book_date || "-" }}</label
      >
    </div>

    <div class="py-4 text-xs text-gray-700  pl-4 italic">
      <p class="">{{content?.eventDate}}  :{{ eventStore.event?.information.date }}</p>
      <p class="">{{content?.produceDate}} : {{ produce?.produce_book_date }}</p>
      <p class="">{{content?.estimateDate}}  : {{ estimateDate }}</p>
    </div> -->
  </div>
</template>

<script setup>
import dayjs  from "dayjs";
const eventStore = useEventStore();
const props = defineProps({
  content: { type: Object, default: {} },
  isEdit: { type: Boolean, required: true },
  eventPackage: { type: String, required: true },
});

const emit = defineEmits(["onEdit"]);

const toggleEditMode = () => {
  emit("onEdit", !props.isEdit);
};

const produce = computed(() => eventStore.event?.produceBook || {
    id: "",
    type: "-",
    date: "",
    url: "",
    sortId: "",
    sortType: "",
  })

const estimateDate = computed(() => {
  if (produce.produce_book_date) {
    return dayjs(produce.produce_book_date).add(5, 'day').format('YYYY-MM-DD');
  }
  return "";
});


</script>
