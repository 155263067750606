<template>
  <AppBlockScreen
    :isShow="eventPackage == 'free' ? true : false"
    @submit="saveChangeFont"
    class="mx-2 mb-20 rounded-lg shadow"
  >
    <template #description>
      <p class="mt-3">ปรับแต่งข้อมูล</p>
      <p class="">
        สำหรับแพคเกจ
        <span class="underline" @click="$router.push('/package/checkout')"
          >Pro</span
        >
        เท่านั้น
      </p>
    </template>

    <!-- EDIT MODE -->
    <event-custom-theme-edit
      v-if="isEdit"
      :content="content"
      :isEdit="isEdit"
      @onEdit="handleChangeEditMode"
      :callback="onSave"
      :eventDate="eventDate"
      :eventTime="eventTime"
      :eventTimeStart="eventTimeStart"
      :eventPackage="eventPackage"
      :groom="groom"
      :bride="bride"
      :settingTheme="settingTheme"

      :templates="props?.theme?.templates"
      :colors="props?.theme?.colors"
      :fonts="fonts"
      :googleFonts="googleFonts"
    />

    <!-- SHOW MODE -->
    <event-custom-theme-show
      v-else
      :content="content"
      :isEdit="isEdit"
      @onEdit="handleChangeEditMode"
      :settingTheme="settingTheme"
      :eventDate="eventDate"
      :eventTime="eventTime"
      :eventTimeStart="eventTimeStart"
      :eventPackage="eventPackage"
      :groom="groom"
      :bride="bride"
    />
  </AppBlockScreen>
</template>
<script setup>
import { useEventStore } from "@/stores/event";
const props = defineProps([
  "content",
  "theme",
  "fonts",
  "googleFonts",
  "bride",
  "groom",
  "eventDate",
  "eventTime",
  "eventTimeStart",
  "settingTheme",
  "saveEventTheme",
]);

const useEvent = useEventStore();
const isEdit = ref(false);

const eventPackage = computed(() => useEvent.event && useEvent.event.package);

const handleChangeEditMode = (_isEdit) => {
  isEdit.value = _isEdit;
};

const onSave = (data) => {
  isEdit.value = false;
  props.saveEventTheme(data);
};
</script>
