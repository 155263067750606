<template>
  <div class="mt-5 mb-5">
    <p class="capitalize font-semibold mb-2 ml-5">
      {{title}}
      <span>{{templateId}}</span>
    </p>

    <div class="px-5 flex justify-center">
      <div class="border  rounded-xl relative">
        <Welcome1 
          v-if="theme?.templateId === 'welcome_1'"
          :theme="theme"
          :brideName="brideName"
          :groomName="groomName"
          :eventDate="eventDate"
          :eventTimeStart="eventTimeStart"
        />

        <Welcome2 
          v-if="theme?.templateId === 'welcome_2'"
          :theme="theme"
          :brideName="brideName"
          :groomName="groomName"
          :eventDate="eventDate"
          :eventTimeStart="eventTimeStart"
        />

        <Welcome3 
          v-if="theme?.templateId === 'welcome_3'"
          :theme="theme"
          :brideName="brideName"
          :groomName="groomName"
          :eventDate="eventDate"
          :eventTimeStart="eventTimeStart"
        />

        <Welcome4
          v-if="theme?.templateId === 'welcome_4'"
          :theme="theme"
          :brideName="brideName"
          :groomName="groomName"
          :eventDate="eventDate"
          :eventTimeStart="eventTimeStart"
        />

        <Welcome5 
          v-if="theme?.templateId === 'welcome_5'"
          :theme="theme"
          :brideName="brideName"
          :groomName="groomName"
          :eventDate="eventDate"
          :eventTimeStart="eventTimeStart"
        />

        <Welcome6 
          v-if="theme?.templateId === 'welcome_6'"
          :theme="theme"
          :brideName="brideName"
          :groomName="groomName"
          :eventDate="eventDate"
          :eventTimeStart="eventTimeStart"
        />

        <Welcome7 
          v-if="theme?.templateId === 'welcome_7'"
          :theme="theme"
          :brideName="brideName"
          :groomName="groomName"
          :eventDate="eventDate"
          :eventTimeStart="eventTimeStart"
        />

        <Welcome8 
          v-if="theme?.templateId === 'welcome_8'"
          :theme="theme"
          :brideName="brideName"
          :groomName="groomName"
          :eventDate="eventDate"
          :eventTimeStart="eventTimeStart"
        /> 
      </div>
    </div>

    <!-- <div class="px-5 flex justify-center">
      <div class="relative w-[300px] rounded-lg" >
        <div class="w-full h-[480px] relative shadow-md rounded-lg">
          <img
            class="w-full h-full object-cover object-top"
            src="~/assets/images/bg-top.G_KpMwGz.webp"
          />
          <div
            class="pointer-events-none absolute bottom-0 left-0 right-0 opacity-20"
          >
            <img
              class="w-full h-full object-cover object-bottom"
              src="~/assets/images/bg-bottom.sg4ZR4P9.webp"
            />
          </div>
        </div>

        <div class="text-center" style="color: rgb(89, 61, 40)">
          <p
            class="text-[12px] tracking-wide font-bold font-['cinzel'] uppercase"
            style="position: absolute; top: 70px; right: 0px; left: 0px"
          >
            The Wedding Of
          </p>
          <div
            class="text-center"
            style="position: absolute; top: 95px; right: 0px; left: 0px"
          >
            <div
              class="capitalize text-center tracking-wide text-[3.2em]"
              :style="{
                fontFamily: fontSelected,
                color: 'rgb(32, 22, 14)',
                fontStyle: italic,
                lineHeight: 1,
              }"
            >
              <p>{{ props?.bride?.firstName }}</p>
              <p>{{ props?.groom?.firstName }}</p>
            </div>
          </div>
        </div>

        <div
       
          class="text-center"
          style="position: absolute; top: 134px; right: 20px; left: 120px"
        >
          <p
            class=" text-md tracking-wide "
            :style="{
              fontFamily: fontSelected,
              fontStyle: 'italic',
              color: 'rgb(0, 0, 0)',
            }"
          >
            and
          </p>
        </div>
        <div
          class="text-center"
          style="position: absolute; top: 205px; right: 0px; left: 0px"
        >
          <div
            class="text-[8px] tracking-wide uppercase"
            style="font-family: cinzel; color: rgb(0, 0, 0)"
          >
            <p>{{ props?.dateCustom?.month }}</p>
          </div>
        </div>
        <div
          class="text-center"
          style="position: absolute; top: 217px; right: 0px; left: 0px"
        >
          <div
            class="text-[20px]tracking-wide"
            style="font-family: cinzel; color: rgb(0, 0, 0)"
          >
            <p>{{ props?.dateCustom?.day }}</p>
          </div>
        </div>
       
        <div
          class="text-center"
          style="position: absolute; top: 240px; right: 0px; left: 0px"
        >
          <div
            class="text-[8px] tracking-wide uppercase"
            style="font-family: cinzel; color: rgb(0, 0, 0)"
          >
            <p>{{ props?.dateCustom?.year }}</p>
          </div>
        </div>
        <div style="position: absolute; top: 222px; right: 0px; left: 0px">
          <div class="flex items-center justify-around">
            <div
              class="text-[8px] tracking-wide"
              style="font-family: cinzel; color: rgb(0, 0, 0)"
            >
              <p class="px-5 border-1.5 border-t border-b border-black">
                {{ props?.dateCustom?.nameWeek }}
              </p>
            </div>
            <div
              class="text-[8px] tracking-wide"
              style="font-family: cinzel; color: rgb(0, 0, 0)"
            >
              <p class="px-5 border-1.5 border-t border-b border-black">
                AT {{ props?.eventTimeStart }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="text-center text-[12px] tracking-wide uppercase space-x-4 w-full flex flex-row justify-center"
          style="
            position: absolute;
            top: 290px;
            right: 0px;
            left: 0px;
            font-family: cinzel;
          "
        >
          <p class="">The ceremony</p>
          <img
            class="w-8 h-auto object-contain"
            src="https://firebasestorage.googleapis.com/v0/b/wedding-theme/o/assets%2Ftemplates-wish-app%2Fwelcome-flower%2Fline-bottom.webp?alt=media&amp;token=e43cd0bc-6f4a-4e9e-8b6c-7e7dd5082803"
            alt=""
          />
        </div>
      </div>
    </div> -->
  
  </div>
</template>

<script setup>
import _ from 'lodash'
import Welcome1 from '../../v2/wish/theme/Welcome_1.vue'
import Welcome2 from '../../v2/wish/theme/Welcome_2.vue'
import Welcome3 from '../../v2/wish/theme/Welcome_3.vue'
import Welcome4 from '../../v2/wish/theme/Welcome_4.vue'
import Welcome5 from '../../v2/wish/theme/Welcome_5.vue'
import Welcome6 from '../../v2/wish/theme/Welcome_6.vue'
import Welcome7 from '../../v2/wish/theme/Welcome_7.vue'
import Welcome8 from '../../v2/wish/theme/Welcome_8.vue'
const props = defineProps({
  title: { type: String, default: 'ตัวอย่างหน้าแรก'},
  bride: { type: Object, required: true },
  groom: { type: Object, required: true },
  eventDate: { type: String, required: true },
  eventTimeStart: { type: Object, required: true },
  theme: { type: Object, required: true, default: {
    templateId: 'welcome_1',
    themeId: 'default_welcome_1',
    fonts: {
      welcome: 'Callem',
      thankyou: ""
    },
    colors: {
      main: "#0855CB",
      background: "#f1f5f9"
    }
  }},
});

const brideName = computed(() => _.capitalize(props?.bride?.firstName)|| 'Bride') 
const groomName = computed(() => _.capitalize(props?.groom?.firstName) || 'Groom') 
</script>
