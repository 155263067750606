<template>
  <div class="relative w-[300px] rounded-lg" >
    <div class="w-full h-[480px] relative shadow-md rounded-lg">
      <div class="absolute top-0 right-0 bottom-0 left-0">
        <img 
          class="w-full h-full object-contain object-left" 
          src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/6f412fb5-6f64-45a0-f008-724a9a4aaf00/hd"
        />  
      </div>

      <div class="absolute top-0 right-0 bottom-0 left-0">
        <img 
          class="w-full h-full object-contain object-right" 
          src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/bef13f03-742a-4d8e-4070-cc47970de500/hd"
        />  
      </div>

      <div class="absolute top-0 right-0 bottom-0 left-0">
        <img 
          class="w-full h-full object-contain object-left" 
          src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/42431b45-cf4b-490e-0cec-8d2b97842100/hd"
        />  
      </div>
      <div class="absolute top-0 right-0 bottom-0 left-0">
        <img 
          class="w-full h-full object-contain object-right" 
          src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/53fd622e-86ce-4ff6-3c13-6e044a9c0f00/hd"
        />  
      </div>
    </div>

    <div class="text-center" style="color: #733D21;">
      
      <div  
        class="text-[12px] tracking-wide font-['cinzel'] uppercase absolute left-0 right-0" 
        style="top: 70px;"
        >
        <p>Save the Date</p>
      </div>

      <div
        class="text-center absolute left-0 right-0" 
        style="position: absolute; top: 105px; right: 0px;"
      >
        <div
          class="capitalize text-center tracking-wide text-[2.2em] py-2 space-y-2"
          :style="{
            fontFamily: props?.theme?.fonts?.welcome,
            color: '#7c5340',
            fontStyle: italic,
            lineHeight: 1,
          }"
        >
          <p>{{ props?.brideName }}</p>
          <p>& {{ props?.groomName }}</p>
        </div>
      </div>
    </div>


    <div class="text-[#733D21]">
      <div 
        class="text-center absolute right-0 left-0" 
        style="top: 210px;"
      >
        <div class="text-[8px] tracking-wide uppercase" style="font-family: cinzel;">
          <p>{{mount}}</p>
        </div>
      </div>

      <div 
        class="text-center absolute right-0 left-0" 
        style="top: 220px;"
      >
        <div class="text-[24px] tracking-wide" style="font-family: cinzel;">
          <p>{{dateNumber}}</p>
        </div>
      </div>

      <div 
        class="text-center absolute right-0 left-0" 
        style="top: 250px;"
      >
        <div class="text-[8px] tracking-wide uppercase" style="font-family: cinzel;">
          <p>{{year}}</p>
        </div>
      </div>

      <div
        class="absolute right-0 left-0"
        style="top: 230px;"
      >
        <div class="flex items-center justify-around">
          <div class="w-1/2  px-8 text-[10px] tracking-wide" style="font-family: cinzel;">
            <p class="border-1.5 text-center border-t border-b border-[#733D21]">{{dayName}}</p>
          </div>

          <div class="w-1/2  px-8  text-[10px] tracking-wide" style="font-family: cinzel;">
            <p class="border-1.5 text-center border-t border-b border-[#733D21]">AT {{time}}</p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="w-full flex flex-col items-center absolute right-0 left-0 " 
      style="font-family: cinzel; top: 260px; color: #733D21;"
    >
      <img class="mt-2 w-24 h-auto object-contain" src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/c0d80a1c-87a4-468a-3665-79ddc269e000/original" alt="">
      <p class="mt-2 text-center text-[8px] tracking-wide uppercase ">Formal invitation follow</p>
    </div>

    <FooterButtons :theme="theme" />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import FooterButtons from './FooterButtons.vue'
const props = defineProps({
  brideName: { type: String, default: 'Bride'},
  groomName: { type: String, default: 'Groom'},
  eventDate: {type: String, default: '2014-12-25'},
  eventTimeStart: { type: String, default: '00:00'},
  theme: { type: Object, default: {}}
})

//-----
const dateNumber = computed( ()=> dayjs(props?.eventDate).format('DD') ||  dayjs().format('DD'))
const dayName = computed( ()=> dayjs(props?.eventDate).format('dddd') ||  dayjs().format('dddd'))
const mount = computed( ()=> dayjs(props?.eventDate).format('MMM') ||  dayjs().format('MMM'))
const year = computed( ()=> dayjs(props?.eventDate).format('YYYY') ||  dayjs().format('YYYY'))
const time = computed( ()=> props?.eventTimeStart ||  '00:00')
//-----
</script>

<style scoped>

</style>