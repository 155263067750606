<template>
  <div
    class="max-w  py-3 bg-white border border-gray-200 rounded-lg shadow  "
  >
    <div class="flex items-center justify-between py-1.5">
      <h2
        class="text-2xl font-semibold px-3 text-gray-600  pb-2 border-gray-300"
      >
        {{content?.title}}
      </h2>
    </div>
    <hr class="mb-3" />

    <div class="px-3 mb-5">
      <p class="text-gray-600 ">{{content?.template?.titleEdit}}</p>

      <div class="w-full">
        <button 
          class="w-full h-10 px-4 text-gray-500 bg-transparent rounded-lg border flex justify-between items-center" 
          @click="openModal('template')"
        >
          <p class="text-left">
            <span>{{ selectedData?.templateId }}</span>
          </p>

          <p ><i class="fa-light fa-chevron-down"></i></p>
        </button>
      </div>
    </div>

    <div class="px-3 mb-5">
      <p class="text-gray-600 ">{{content?.theme?.titleEdit}}</p>
      <div class="w-full border rounded-lg py-2 pb-8">
        <div class="px-5">
          <p class="text-gray-600">{{content?.theme?.main}}</p>
          <div class="mt-2 grid grid-cols-6 md:grid-cols-8 gap-3">
            <div v-for="(color, colorIndex) in mainColorList" :key="'main-' + colorIndex">
              <div class="relative">
                <p v-if="colorIndex == 0" class="absolute top-8 text-[9px]">
                  Default
                </p>

                <div 
                  class="w-8 h-8 border rounded-full flex justify-center items-center" 
                  :style="`background-color: ${color?.main}`"
                  @click="selectMainColor(color)"
                >
                  <span v-if="color?.themeId === selectedData?.themeId" class="text-white">
                    <i class="fa-sharp fa-solid fa-check"></i>
                  </span>
                </div>
                
              </div>
            </div>
          </div>
        </div>

        <hr  class="my-5"/>


        <div class="px-5">
          <p class="text-gray-600">{{content?.theme?.backgroundEdit}}</p>

          <div class="mt-2 grid grid-cols-6 md:grid-cols-8 gap-3">
            <div v-for="(bg, bgIndex) in backgroundList" :key="'bg-' + bgIndex">
              <div class="">
                <div 
                  class="w-8 h-8 border rounded-full flex justify-center items-center" 
                  :style="`background-color: ${bg}`"
                  @click="selectBackgroundColor(bg)"
                >
                  <span v-if="bg === selectedData?.colors?.background" class="text-gray-600">
                    <i class="fa-sharp fa-solid fa-check"></i>
                  </span>
                </div>
              </div>
            </div>
            
          </div>

        </div>
      </div>
    </div>

    <div class="px-3 mb-5">
      <p class="text-gray-600 ">{{content?.font?.titleEdit}}</p>

      <div class="w-full">
        <button 
          class="w-full h-10 px-4 text-gray-500 bg-transparent rounded-lg border flex justify-between items-center" 
          @click="openModal('font')"
        >
          <p class="text-left ">
            <span>{{ selectedData?.fonts?.welcome }}</span>
            <span> - </span>
            <span :style="`font-family: ${selectedData?.fonts?.welcome};`">{{eventName || 'Bride & Groom'}}</span>
          </p>

          <p ><i class="fa-light fa-chevron-down"></i></p>
        </button>
      </div>
    </div>

    <div class="flex justify-end mb-2 px-3">
      <button
        class="py-1.5 px-3 m-1 text-center bg-blue-700 border rounded-md text-white hover:bg-violet-500 hover:text-gray-100 "
        @click="onSave"
      >
        Save
      </button>
      <button
        class="py-1.5 px-3 m-1 text-center bg-red-700 border rounded-md text-white hover:bg-violet-500 hover:text-gray-100 "
        @click="toggleEditMode"
      >
        Cancel
      </button>
    </div>

    <hr />
    <!-- Show Output -->
    <event-custom-theme-preview
      :title="content?.title2"
      :groom="groom"
      :bride="bride"
      :eventDate="eventDate"
      :eventTimeStart="eventTimeStart"
      :theme="selectedData"
    />

    <Teleport v-if="isShowFontModal || isShowTemplateModal" to="body">
      <UtilsModalDefault :isShow="isShowFontModal" @closed="closeModal">
        <template v-slot:body>
          <div class="w-[360px] h-[85vh] bg-white text-gray-700 text-left rounded-lg p-5 overflow-y-auto">
            <div class="sticky">
              <p class="text-xl" >เลือกฟ้อนท์</p>
            </div>

            <div class="my-2">
              <!-- <p class="text-xs text-gray-400">ค้นหาฟ้อนท์</p> -->
              <input v-model="inputSearch" @input="onInputSearchFont" class="w-full h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-300 border rounded-lg focus:shadow-outline" type="text" placeholder="ค้นหาฟ้อนท์"/>
            </div>


            <div v-for="(font, index) in fontList" :key="'font-' + index">
              <p class="p-2 bg-gray-100 rounded-lg mt-5" v-if="font?.type">{{ font?.title }}</p>
              <div class="ml-5 space-y-5 mt-3" v-if="font?.family" @click="selectFont(font?.family)">
                <div>
                  <p>
                    <span>{{ font?.family }}</span>
                    <span> - </span>
                    <span :style="`font-family: ${font?.family};`" class="text-xs">{{eventName}}</span>
                    <span class="ml-5 text-green-500" v-if="selectedData?.fonts?.welcome === font?.family"><i class="fa-regular fa-check"></i></span>
                  </p>
                </div>
              </div>
            </div>
      
          </div>
            <div class="mt-4">
              <button
                @click="closeModal"
                type="button"
                class="block py-2 px-6 font-medium mx-auto focus:outline-none border items-center rounded-lg focus:z-10"
              >
                <i class="fa-solid fa-xmark mx-1 text-lg"></i>
                Close
              </button>
            </div>
        </template>
      </UtilsModalDefault>

      <UtilsModalDefault :isShow="isShowTemplateModal" @closed="closeModalTemplate">
        <template v-slot:body>
          <div class="w-[360px] h-[85vh] md:h-[82vh] bg-white text-gray-700 text-left rounded-lg p-5 overflow-y-auto">
            <div>
              <p>{{content?.template?.titleEdit}}</p>

              <div class="max-w-2xl mx-auto mt-2">
                <div class="relative">
                  <img
                    :src="props?.templates[currentIndex]?.image"
                    alt="Image Preview"
                    class="w-full h-[60vh] object-contain rounded-lg drop-shadow-lg"
                  />

                  <button
                    @click="prevImage"
                    class="absolute -left-2 top-1/2 transform -translate-y-1/2 text-white bg-gray-600 px-2.5 py-0.5 rounded-full shadow-lg"
                  >
                    <span><i class="fa-regular fa-angle-left"></i></span>
                  </button>

                  <button
                    @click="nextImage"
                    class="absolute -right-2 top-1/2 transform -translate-y-1/2 text-white bg-gray-600 px-2.5 py-0.5 rounded-full shadow-lg"
                  >
                    <span><i class="fa-regular fa-angle-right"></i></span>
                  </button>
                </div>

                <!-- Navigation Dots Section -->
                <div class="flex justify-center mt-3">
                  <div  
                    v-for="(template, index) in props?.templates" 
                    @click="goToImage(index)"
                    :key="index"
                  >
                    <div class="relative">
                      <img :src="template?.image" class="w-12 h-12 object-contain rounded-lg shadow-lg">

                      <div class="w-full absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center" v-if="currentIndex === index">
                        <span class="text-green-500 text-2xl"><i class="fa-solid fa-badge-check"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-3 flex justify-between items-center">
                <button @click="closeModalTemplate" class="space-x-3 w-44 h-10 px-5 m-2 text-gray-600 bg-white border border-gray-600 rounded-lg">
                  <span><i class="fa-regular fa-xmark"></i></span>
                  <span>Close</span>
                </button>
                <button @click="selectTemplate" class="w-44 h-10 px-5 m-2 text-white bg-green-600 border border-green-600 rounded-lg">Select</button>
              </div>
            </div>
          </div>
          
        </template>
      </UtilsModalDefault>
    </Teleport>
  </div>
</template>

<script setup>
import _ from 'lodash'
const emit = defineEmits(["onEdit"]);
const props = defineProps({
  content: { type: Object, default: {}},
  isEdit: { type: Boolean, required: true },
  callback: { type: Function, required: true },
  eventDate: { type: String, required: true },
  eventTime: { type: String, required: true },
  eventTimeStart: { type: String, required: true },
  groom: { type: Object, required: true },
  bride: { type: Object, required: true },

  templates: { type: Array, required: true, default: []},
  colors: { type: Array, required: true, default: []},
  fonts: { type: Array, required: true },
  googleFonts: { type: Array, required: true },

  settingTheme: { 
    type: Object, 
    required: true, 
    default: {
      templateId: 'welcome_1',
      themeId: 'default_welcome_1',
      fonts: {
        welcome: 'Callem',
        thankyou: ""
      },
      colors: {
        main: "#0855CB",
        background: "#f1f5f9"
      }
    }
  },
});

const eventName = computed(() => {
  return `${props?.bride?.firstName || 'Bride'} & ${props?.groom?.firstName || 'Groom' }`
})

const mainColorList = computed(() => {
  const defaultColor = _.filter(props?.colors, c => c?.default === true && c?.themeId === `default_${holderData.value?.templateId}`)
  const filtered = _.filter(props?.colors, w => !w?.default)

  if(defaultColor.length > 0) {
    return _.concat(defaultColor, filtered)
  }

  return filtered || []
})

const backgroundList = computed(() => {
  const data = _.filter(props?.colors, c => c?.themeId === selectedData?.themeId)
  if(data.length > 0 ) {
    return data[0]?.backgrounds || []
  }

  return []
})



const selectedData = reactive(_.cloneDeep(props.settingTheme))
const holderData = ref(_.cloneDeep(props.settingTheme))
const fontList = ref([])
const holderFontList = ref([])

const isShowFontModal = ref(false)
const inputSearch = ref('')

// Reactive state for current image index
const isShowTemplateModal = ref(false)
const currentIndex = ref(0)
const holderCurrentIndex = ref(0)

onMounted(() => {
  setFontList()

  const index = _.findIndex(props?.templates, t => t?.templateId === props?.settingTheme?.templateId) || 0
  currentIndex.value = index
  holderCurrentIndex.value = index
})

watch(() => props?.fonts, () => {
  setFontList()
})
watch(() => props?.googleFonts, () => {
  setFontList()
})

const setFontList = () => {
  const transformFontList = _.map(props?.fonts, font => {
    return { family: font }
  })
  const defaultList = [{type: 'default', title: 'Recommend'}, ...transformFontList]

  const transformGoogleList = _.map(props?.googleFonts, font => {
    return { family: font }
  })
  const googleList = [{type: 'google', title: 'All Fonts'}, ...transformGoogleList]

  const allList = _.concat(defaultList, googleList)
  fontList.value = allList
  holderFontList.value = allList
}

const openModal = (type) => {

  switch (type) {
    case 'font':
      isShowFontModal.value = true
      break;
    case 'template':
      isShowTemplateModal.value = true
      break;
  
    default:
      break;
  }
}

const closeModal = () => {
  isShowFontModal.value = false
  isShowTemplateModal.value = false
  setFontList()
  inputSearch.value = ''

}

const closeModalTemplate = () => {
  isShowTemplateModal.value = false
  currentIndex.value = holderCurrentIndex.value

}

const onInputSearchFont = _.debounce(() => {
  if (!inputSearch.value || inputSearch.value == "") {
    setFontList()
  }

  const result = [];
  _.forEach(holderFontList.value, (font) => {
    const lowerFont = _.lowerCase(font?.family);
    const lowerSearch = _.lowerCase(inputSearch.value);

    if (_.includes(lowerFont, lowerSearch)) {
      result.push(font);
    }
  });

  fontList.value = result
}, 500)



// Methods to handle navigation
const nextImage = () => {
  currentIndex.value = (currentIndex.value + 1) % props?.templates?.length
}

const prevImage = () => {
  currentIndex.value = (currentIndex.value - 1 + props?.templates?.length) % props?.templates?.length
}

const goToImage = (index) => {
  currentIndex.value = index
}

const displayPreviewUrl = () => {
  return props?.templates[currentIndex]?.image || 'https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg'
}

const selectTemplate = () => {

  const templateData = _.filter(props?.templates, (t, tIndex) => tIndex === currentIndex.value)[0]

  if(templateData) {
    selectedData.templateId = templateData?.templateId
    selectedData.themeId = templateData?.themeId
    selectedData.fonts = templateData?.fonts
    selectedData.colors = templateData?.colors
    holderData.value = templateData
    // console.log(selectedData)
  }

  
  closeModal()
}

const selectFont = (font) => {
  selectedData.fonts = {
    welcome: font,
    thankyou: ""
  }

  // console.log(selectedData)
  closeModal()
}

const selectMainColor = (color) => {
  selectedData.themeId = color?.themeId
  selectedData.colors = {
    ...selectedData?.colors,
    main: color?.main,
    background: color?.backgrounds[0],
  }
}

const selectBackgroundColor = (bgColor) => {
  // console.log(bgColor)

  selectedData.colors = {
    ...selectedData?.colors,
    background: bgColor,
  }
}

const toggleEditMode = () => {
  emit("onEdit", !props.isEdit);
};

const onSave = () => {
  toggleEditMode();

  // console.log(selectedData)

  props.callback(selectedData)
};
</script>
