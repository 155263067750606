<template>
  <div class="relative w-[300px] rounded-lg" >
    <div class="w-full h-[480px] relative shadow-md rounded-lg">
      <img
        class="w-full h-full object-cover object-top"
        src="~/assets/images/bg-top.G_KpMwGz.webp"
      />
      <div
        class="pointer-events-none absolute bottom-0 left-0 right-0 opacity-20"
      >
        <img
          class="w-full h-full object-cover object-bottom"
          src="~/assets/images/bg-bottom.sg4ZR4P9.webp"
        />
      </div>
    </div>

    <div class="text-center" style="color: rgb(89, 61, 40)">
      <p
        class="text-[12px] tracking-wide font-bold font-['cinzel'] uppercase"
        style="position: absolute; top: 70px; right: 0px; left: 0px"
      >
        The Wedding Of
      </p>
      <div
        class="text-center"
        style="position: absolute; top: 95px; right: 0px; left: 0px"
      >
        <div
          class="capitalize italic text-center tracking-wide text-[3.2em]"
          :style="{
            fontFamily: props?.theme?.fonts?.welcome,
            color: 'rgb(32, 22, 14)',
            fontStyle: italic,
            lineHeight: 1,
          }"
        >
          <p>{{ props?.brideName }}</p>
          <p>& {{ props?.groomName }}</p>
        </div>
      </div>
    </div>

    <!-- <div
    
      class="text-center"
      style="position: absolute; top: 134px; right: 20px; left: 120px"
    >
      <p
        class=" text-md tracking-wide "
        :style="{
          fontFamily: props?.theme?.fonts?.welcome,
          fontStyle: 'italic',
          color: 'rgb(0, 0, 0)',
        }"
      >
        and
      </p>
    </div> -->
    <div
      class="text-center"
      style="position: absolute; top: 205px; right: 0px; left: 0px"
    >
      <div
        class="text-[8px] tracking-wide uppercase"
        style="font-family: cinzel; color: rgb(0, 0, 0)"
      >
        <p>{{ month }}</p>
      </div>
    </div>
    <div
      class="text-center"
      style="position: absolute; top: 217px; right: 0px; left: 0px"
    >
      <div
        class="text-[20px]tracking-wide"
        style="font-family: cinzel; color: rgb(0, 0, 0)"
      >
        <p>{{ dateNumber }}</p>
      </div>
    </div>
    
    <div
      class="text-center"
      style="position: absolute; top: 240px; right: 0px; left: 0px"
    >
      <div
        class="text-[8px] tracking-wide uppercase"
        style="font-family: cinzel; color: rgb(0, 0, 0)"
      >
        <p>{{ year }}</p>
      </div>
    </div>
    <div style="position: absolute; top: 222px; right: 0px; left: 0px">
      <div class="flex items-center justify-around">
        <div
          class="text-[8px] tracking-wide"
          style="font-family: cinzel; color: rgb(0, 0, 0)"
        >
          <p class="px-5 border-1.5 border-t border-b border-black">
            {{ dayName }}
          </p>
        </div>
        <div
          class="text-[8px] tracking-wide"
          style="font-family: cinzel; color: rgb(0, 0, 0)"
        >
          <p class="px-5 border-1.5 border-t border-b border-black">
            AT {{ time }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="text-center text-[12px] tracking-wide uppercase space-x-4 w-full flex flex-row justify-center"
      style="
        position: absolute;
        top: 290px;
        right: 0px;
        left: 0px;
        font-family: cinzel;
      "
    >
      <p class="">The ceremony</p>
      <img
        class="w-8 h-auto object-contain"
        src="https://firebasestorage.googleapis.com/v0/b/wedding-theme/o/assets%2Ftemplates-wish-app%2Fwelcome-flower%2Fline-bottom.webp?alt=media&amp;token=e43cd0bc-6f4a-4e9e-8b6c-7e7dd5082803"
        alt=""
      />
    </div>

    <FooterButtons :theme="theme" />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import FooterButtons from './FooterButtons.vue'
const props = defineProps({
  brideName: { type: String, default: 'Bride'},
  groomName: { type: String, default: 'Groom'},
  eventDate: {type: String, default: '2014-12-25'},
  eventTimeStart: { type: String, default: '00:00'},
  theme: { type: Object, default: {}}
})

//-----
const dateNumber = computed( ()=> dayjs(props?.eventDate).format('DD') ||  dayjs().format('DD'))
const dayName = computed( ()=> dayjs(props?.eventDate).format('dddd') ||  dayjs().format('dddd'))
const mount = computed( ()=> dayjs(props?.eventDate).format('MMM') ||  dayjs().format('MMM'))
const year = computed( ()=> dayjs(props?.eventDate).format('YYYY') ||  dayjs().format('YYYY'))
const time = computed( ()=> props?.eventTimeStart ||  '00:00')
//-----
</script>

<style scoped>

</style>