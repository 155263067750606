<template>
  <div class="absolute bottom-6 left-0 right-0 px-5 space-y-1">
    <button class="w-full h-9 px-6 text-white  rounded-full shadow-lg text-xs" :style="`background-color: ${mainColor};`">เริ่มเขียนคำอวยพร</button>
    <button class="w-full h-9 px-6 bg-[#ffffffc7] rounded-full shadow-lg text-xs" :style="`color: ${mainColor};`">แนบสลิปพร้อมเพย์</button>
    <button class="w-full h-9 px-6 bg-[#ffffffc7] rounded-full shadow-lg text-xs" :style="`color: ${mainColor};`">ดูคำอวยพร</button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  theme: { type: Object, default: {}}
})

const mainColor = computed(() => props?.theme?.colors?.main || '#0855cb')

</script>

<style scoped>

</style>