<template>
  <div
    class="max-w py-3 bg-white border border-gray-200 rounded-lg shadow "
  >
    <!-- Title for the Card -->
    <h2 class="text-2xl font-semibold px-3 text-gray-600 pb-2 border-gray-300">
      {{content?.title}}
    </h2>
    <hr class="mb-3" />

    <div class="px-3 mb-2 mt-8 ">
      <label class="text-gray-600 ">{{content?.sort?.type}}</label>

      <div class="space-y-3 mt-2">
        <div 
          v-for="sort in content?.sort?.list" :key="sort?.id"  
          class="w-full border rounded-lg p-3 flex items-center"
          :class="{'border-green-500' : produce.sortId === sort?.id}"
          @click="selectProduceSortType(sort)"
        >
          <p class="mr-5" v-if="produce.sortId !== sort?.id">
            <i class="fa-regular fa-circle"></i>
          </p>
          <p 
            v-if="produce.sortId === sort?.id"
            class="mr-5 text-green-500" 
          >
            <i class="fa-regular fa-circle-check"></i>
          </p>
          <div>
            <p>{{sort?.type}}</p>
          </div>

        </div>
      </div>
    </div>

    <div class="px-3 mb-2">
      <label class="text-gray-600 ">{{content?.selectedType}}</label>

      <div class="space-y-3 mt-2">
        <div 
          v-for="option in content?.list" :key="option?.id"  
          class="w-full border rounded-lg p-3 flex items-center"
          :class="{'border-green-500' : produce.id === option?.id}"
          @click="selectProduceType(option)"
        >
          <p class="mr-5" v-if="produce.id !== option?.id">
            <i class="fa-regular fa-circle"></i>
          </p>
          <p 
            v-if="produce.id === option?.id"
            class="mr-5 text-green-500" 
          >
            <i class="fa-regular fa-circle-check"></i>
          </p>
          <div>
            <p>{{option?.type}}</p>
            <div v-if="option?.id === 'design-by-user'" class="mt-2 text-sm italic">
              <p class="space-x-2">
                <span><i class="fa-brands fa-youtube text-red-600"></i> {{content?.guidelineText}} : </span>
                <span class="text-blue-500 space-x-2" @click.stop="openLinkYoutube(content?.guidelineLink)" >{{content?.guidelineLink}} <i class="fa-solid fa-up-right-from-square"></i></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="px-3 mt-7 mb-5">
      <p class="">(Optional) {{content?.url}}</p>
      <input 
        class="w-full h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg"
        type="text" 
        v-model="produce.url"
      />

    </div>
    <div class="px-3 my-5">
      <p class="text-sm text-gray-400 italic mt-2">{{content?.remark}}</p>
    </div>

    <!-- <div class="px-3 mb-2">
      <label for="shipping_date" class="block text-gray-700    mb-2"
        >{{content?.produceBookDate}}</label
      >
      <input
        type="date"
        id="shipping_date"
        name="shipping_date"
        format="yyyy-MM-dd"
        :min="minDate"
        :max="maxDate"
        class="shadow appearance-none border  bg-white   rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-gray-200"
        required
        v-model="address.date"
        :disabled="!eventStore.isOwner"
      />
      <div class="py-4 text-xs text-gray-700  pl-4 italic">

        <p class="">{{content?.eventDate}}  :{{ eventStore.event?.information.date }}</p>
        <p class="">{{content?.produceDate}} : {{ address?.date }}</p>
        <p class="">{{content?.estimateDate}}  : {{ estimateDate }}</p>
      </div>
    </div> -->

    <div class="flex justify-end mt-1 px-3">
      <button
        class="py-1.5 px-3 m-1 text-center bg-blue-700 border rounded-md text-white hover:bg-violet-500 hover:text-gray-100 "
        @click="onSave"
      >
        Save
      </button>
      <button
        class="py-1.5 px-3 m-1 text-center bg-red-700 border rounded-md text-white hover:bg-violet-500 hover:text-gray-100 "
        @click="toggleEditMode"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script setup>
import dayjs  from "dayjs";
import _ from 'lodash'
const eventStore = useEventStore();
const emit = defineEmits(["onEdit"]);
const props = defineProps({
  content: { type: Object, default: {} },
  isEdit: { type: Boolean, required: true },
  callback: { type: Function, required: true },
});


// const minDate = ref (eventStore.event?.information?.date);
// const maxDate = ref( dayjs(minDate.value).add(14, 'day').format('YYYY-MM-DD'))
const holderProduceData = computed(() => _.cloneDeep(eventStore.event?.produceBook ))
const produce = reactive(
    holderProduceData.value || {
    id: "",
    date: "",
    type: "",
    url: "",
    sortId: "",
    sortType: "",
  }
);

const toggleEditMode = () => {
  emit("onEdit", !props.isEdit);
  produce.id = holderProduceData.value?.id || ''
  produce.type = holderProduceData.value?.type || ''
  produce.date = holderProduceData.value?.date || ''
  produce.url = holderProduceData.value?.url || ''
};

const selectProduceType = (data) => {
  produce.id =  data?.id
  produce.type =  data?.type
}

const selectProduceSortType = (data) => {
  produce.sortId =  data?.id
  produce.sortType =  data?.type
}

const onSave = () => {
  props.callback(produce);
};


const openLinkYoutube = (url) => {
  if(!url) {
    return
  }
  window.open(url, '_blank');
}


</script>
