<template>
  <div class="p-3">
    <TemplatePreviewModal
      :imgUrl="previewSelected.imgUrl"
      :isPreview="previewSelected.isPreview"
      :isActive="previewSelected.isActive"
      @changeStatus="onChangeStatusInModal"
      @closed="previewSelected.isPreview = false"
    />

    <div class=" mb-20">
      <div class="mb-4 bg-white p-4 rounded-lg">
        <p class="text-gray-400 text-sm italic">{{content?.remark}}</p>
      </div>

      <div class="bg-white p-4 rounded-lg">

        <!-- Tab Header -->
        <div v-for="tab in tabs" :key="tab.name" class="mt-3">
          <div class="border-b border-gray-200 bg-white z-10 w-full mb-5">           
            <nav
              class="flex flex-row space-x-8 justify-between items-center"
              aria-label="Tabs"
            >
              <span class='border-transparent text-gray-800 font-extrabold text-xl'>{{ tab.name }}  ({{ Object.keys(templateHolder[tab.id]).length }})</span>
              <span class='border-transparent text-green-800 font-extrabold'>Actived :   {{ selectedQty(tab.id) }}</span>
            </nav>
          </div>

          <!-- Tab Body -->
          <div class="mb-10 grid grid-cols-2 gap-4">
            <div
              class="rounded overflow-hidden shadow-lg w-full relative"
              v-for="(item, index) in templateHolder[tab.id]"
              :key="item"
            >
              <NuxtImg
                class="w-full"
                :src="item.thumbnail"
                alt="Sunset in the mountains"
                @click="onPreviewTemplate(item, index)"
                loading="lazy"
              />
    
              <div
                v-if="templateSelected && templateSelected[index]"
                class="absolute top-0 right-0 opacity-100"
              >
                <span
                  class="text-white text-xs rounded-tr bg-green-700 px-2 py-1 text-right"
                  >ACTIVE
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useEventStore } from "@/stores/event";
import { useTemplateStore } from "@/stores/template";
import { deleteField } from "firebase/firestore";

import { useContentWish } from '~/composables/content/v2/useContentWish'
const { content } = useContentWish('wish-template')

const eventStore = useEventStore();
const templateStore = useTemplateStore();
const templateHolder = computed(() => templateStore.templatesHolder);
const tabCurrent = ref({
  name: "Standard",
  href: "#",
  count: "52",
  order: 1,
  id: "standard",
});
const templateSelected = ref({});
const previewSelected = ref({ imgUrl: "", isPreview: false });
const tabs = [
  { name: "Standard", href: "#", count: "52", order: 1, id: "standard" },
  { name: "Remove BG", href: "#", count: "6", order: 3, id: "remove" },
];

watchEffect(() => {
  templateSelected.value = templateStore.templateSelected;
});

const templateSelectedQty = computed(() => {
  let qty = {};
  for (let key in templateHolder.value) {
    for (let keyItem in templateHolder.value[key]) {
      if (
        templateStore.templateSelected &&
        templateStore.templateSelected[keyItem]
      ) {
        if (qty[key] == undefined) {
          qty[key] = 0;
        }
        qty[key] += 1;
      }
              
    }
  }

  return qty;
});

const selectedQty = (tabId) => {
  return (templateSelectedQty.value && templateSelectedQty.value[tabId]) || 0;
};

const onPreviewTemplate = (template, templateId) => {
  previewSelected.value = {
    imgUrl: template.thumbnail,
    isPreview: true,
    isActive: templateSelected.value[templateId] || false,
    templateId,
  };
};

const onChangeStatusInModal = () => {
  templateSelected.value[previewSelected.value.templateId] =
    !previewSelected.value.isActive;
  saveTemplateSelected(previewSelected.value.templateId);
};

const saveTemplateSelected = async (templateId) => {
  let payload = {};
  const limit = 2;
  const isCanActiveTemplate = checkActiveMoreThanLimit(limit);
  if (!isCanActiveTemplate && eventStore.event.package == "free") {
    alert(`Package Free จำกัดจำนวน ${limit} Template`);
    templateSelected.value[templateId] = false;
    return;
  }

  const isCanDeactiveTemplate = checkActiveLessThanLimit();
  if (!isCanDeactiveTemplate) {
    alert(`ไม่สามารถยกเลิกได้ เนื่องจาก Template ต้องห้ามน้อยกว่า 1`);
    templateSelected.value[templateId] = true;
    return;
  }

  for (const templateId in templateSelected.value) {
    payload[templateId] = true;

    if (!templateSelected.value[templateId]) {
      payload[templateId] = deleteField();
    }
  }

  await templateStore.updateTemplateSelected({ templates: payload });
};

const checkActiveMoreThanLimit = (limit = 2) => {
  let tabId = tabCurrent.value.id;
  const matched = Object.keys(templateSelected.value).filter(
    (key) => key.startsWith(tabId) && templateSelected.value[key]
  );
  if (matched.length > limit) {
    return false;
  }

  return true;
};

const checkActiveLessThanLimit = (limit = 1) => {
  let tabId = tabCurrent.value.id;
  const matched = Object.keys(templateSelected.value).filter(
    (key) => key.startsWith(tabId) && templateSelected.value[key]
  );
  if (matched.length < limit) {
    return false;
  }

  return true;
};
</script>
