<template>
  <AppBlockScreen
    :isShow="eventPackage == 'free' ? true : false"
    @submit="saveChangeFont"
    class="mx-2 mb-20 rounded-lg shadow"
  >
    <template #description>
      <p class="mt-3">ปรับแต่งข้อมูล</p>
      <p class="">
        สำหรับแพคเกจ
        <span class="underline" @click="$router.push('/package/checkout')"
          >Pro</span
        >
        เท่านั้น
      </p>
    </template>

    
    <!-- Edit Mode -->
    <event-produce-book-edit
      v-if="isEdit"
      :content="content"
      @onEdit="handleChangeEditMode"
      :callback="onSave"
      :isEdit="isEdit"
    />

    <!-- Show Mode -->
    <event-produce-book-show
      v-else
      :content="content"
      @onEdit="handleChangeEditMode"
      :isEdit="isEdit"
      :eventPackage="eventPackage"
    />
  </AppBlockScreen>
</template>

<script setup>
import { useEventStore } from "@/stores/event";
const useEvent = useEventStore();
const props = defineProps(["content"]);
const eventPackage = computed(() => useEvent.event && useEvent.event.package);
const isEdit = ref(false);

const handleChangeEditMode = (_isEdit) => {
  isEdit.value = _isEdit;
};

const onSave = (payload) => {
  isEdit.value = false;
  useEvent.saveProduceBook({
    id: payload?.id || "",
    type: payload?.type || "",
    date: payload?.date || "",
    url: payload?.url || "",
    sortId: payload?.sortId || "sort-by-timeline",
    sortType: payload?.sortType || "เรียงตาม Timeline",
  });
};

</script>

<style scoped>

</style>